import BonusService from "@/libs/bonus.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBonusGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.getBonusGroupLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addBonusGroup(ctx, bonusGroupData) {
      return new Promise((resolve, reject) => {
        BonusService.addBonusGroup(bonusGroupData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editBonusCode(ctx, bonusData) {
      return new Promise((resolve, reject) => {
        BonusService.editBonusCode(bonusData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editBonusGroup(ctx, bonusGroupData) {
      return new Promise((resolve, reject) => {
        BonusService.editBonusGroup(bonusGroupData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBonusCodes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.getBonusCodeLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchManagerCashback
    (ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.getManagerCashbackLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportManagerCashback
    (ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.exportManagerCashback(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    
    fetchBonusCodeProduct(ctx) {
      return new Promise((resolve, reject) => {
        BonusService.getBonusCodeProduct()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBonusCodeLevel(ctx) {
      return new Promise((resolve, reject) => {
        BonusService.getBonusCodeLevel()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addBonusCode(ctx, bonusData) {
      return new Promise((resolve, reject) => {
        BonusService.addBonusCode(bonusData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchMemberBonusCode(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.getMemberBonusCode(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStatusMemberBonusCode(ctx, memberBonusCodeData) {
      return new Promise((resolve, reject) => {
        BonusService.updateStatusMemberBonusCode(memberBonusCodeData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBonusTypes(ctx) {
      return new Promise((resolve, reject) => {
        BonusService.getBonusTypes()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBonusCodesManager(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.getBonusCodesManager(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProductID(ctx) {
      return new Promise((resolve, reject) => {
        BonusService.getGameTypeFilters()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBonusNewMember(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.getBonusNewMember(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // editBonusNewMember(ctx,queryParams) {
    //   return new Promise((resolve, reject) => {
    //     BonusService.editBonusNewMember(queryParams)
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },
    editBonusNewMember(ctx, bonusData) {
      return new Promise((resolve, reject) => {
        BonusService.editBonusNewMember(bonusData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBonusCodesDetail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.getBonusCodesDetail(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportBonusNewMember(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.exportBonusNewMember(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportBonusManager(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.exportBonusManager(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserBonusRequest(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.getUserBonusRequest(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    updateUserBonusRequestStatus(ctx, bonusData) {
      return new Promise((resolve, reject) => {
        BonusService.updateUserBonusRequestStatus(bonusData)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    updateExpireBonus(ctx, memberBonusCodeData) {
      return new Promise((resolve, reject) => {
        BonusService.updateExpireBonus(memberBonusCodeData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateBonus(ctx, data) {
      return new Promise((resolve, reject) => {
        BonusService.updateBonus(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserRollCall(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        BonusService.getUserRollCall(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
  },
};
