import CurrencyService from "@/libs/currency.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCurrencies(ctx) {
      return new Promise((resolve, reject) => {
        CurrencyService.getCurrencyLists()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOptionStatus(ctx) {
      return new Promise((resolve, reject) => {
        CurrencyService.getOptionStatusLists()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAdjustBalanceReason(ctx) {
      return new Promise((resolve, reject) => {
        CurrencyService.getAdjustBalanceReason()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPaymentType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        CurrencyService.getPaymentTypeLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
