<template>
  <button class="btn btn-primary" @click="exportExcel">
    <slot></slot>
  </button>
</template>

<script>
import XLSX from "xlsx/xlsx";

export default {
  name: "VueExportExcel",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "excel",
    },
    sheetName: {
      type: String,
      default: "SheetName",
    },
    fileType: {
      type: String,
      default: "xlsx",
      validator: (val) => ["xlsx", "xls"].includes(val),
    },
  },
  methods: {
    exportExcel() {
      const createXLSLFormatObj = [];
      const newXlsHeader = [];
      const vm = this;
      console.log(112233,vm.columns);
      console.log(4433,vm.columns);
      if (vm.columns.length === 0) {
        return;
      }
      if (vm.data.length === 0) {
        return;
      }
      vm.columns.map((column) => {
        newXlsHeader.push(column.label);
      });
      createXLSLFormatObj.push(newXlsHeader);
      vm.data.map((value) => {
        const innerRowData = [];
        vm.columns.map((val) => {
          let fieldValue = value[val.field];
          if (val.field.split(".").length > 1) {
            fieldValue = this.getNestedValue(value, val.field);
          }
          if (val.dataFormat && typeof val.dataFormat === "function") {
            innerRowData.push(val.dataFormat(fieldValue));
          } else {
            innerRowData.push(fieldValue);
          }
        });
        createXLSLFormatObj.push(innerRowData);
      });
      const fileName = `${vm.fileName}.${vm.fileType}`;
      const ws_name = vm.sheetName;
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, fileName);
    },
    getNestedValue(object, string) {
      // eslint-disable-next-line no-param-reassign
      string = string.replace(/\[(\w+)\]/g, ".$1");
      // eslint-disable-next-line no-param-reassign
      string = string.replace(/^\./, "");
      const a = string.split(".");
      for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in object) {
          // eslint-disable-next-line no-param-reassign
          object = object[k];
        } else {
          return;
        }
      }
      // eslint-disable-next-line consistent-return
      return object;
    },
  },
};
</script>
