<template>
    <b-card no-body>
      <b-card-body>
        <b-row class="filter">
          <!-- Name -->

          <b-col cols="12" md="4" class="mb-0">
            <label>{{ $t("User Name") }}</label>
            <b-form-input
              v-model="filter.username"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
            />
          </b-col>
          <b-col cols="12" md="4" class="d-flex align-items-end justify-content-end">
            <b-button variant="outline-secondary" @click="resetFilter()">
              {{ $t("Reset") }}
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              {{ $t("Search") }}
            </b-button>
          </b-col>
          <!-- action -->
          <!-- <b-col cols="12" class="d-flex justify-content-end">
            <b-button variant="outline-secondary" @click="resetFilter()">
              {{ $t("Reset") }}
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              {{ $t("Search") }}
            </b-button>
          </b-col> -->
        </b-row>
      </b-card-body>
    </b-card>
</template>

<script>
import {
  BFormInput,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardBody,
    vSelect,
    flatPickr,
    BFormGroup,
    BCardActions,
  },
  props: {
    userNameFilter: {
      type: [String, null],
      default: null,
    },
  },
  methods: {},
  setup(props, { emit }) {
    const productIdOptions = ref();

    const filter = ref({
      username: props.userNameFilter,
    });
    const resetFilter = () => {
      emit("update:userNameFilter", null);
      filter.value = {
        username: null,
      };
    };

    const searchFilter = () => {
      emit("update:userNameFilter", filter.value.username);
    };
    return {
      resetFilter,
      searchFilter,
      filter,
      productIdOptions,
      // bonusCodeIdOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
