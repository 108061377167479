/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class CurrencyService {
  getCurrencyLists() {
    return instance.get("/api/currency/index", { headers: authHeader() });
  }
  getOptionStatusLists() {
    return instance.get("/api/txn-status/index", { headers: authHeader() });
  }
  getAdjustBalanceReason() {
    return instance.get("/api/adjust-balance-reason/index", {
      headers: authHeader(),
    });
  }
  getPaymentTypeLists(queryParams) {
    const pars = {
      id: queryParams.id,
    };
    return instance.get("/api/txn-type/index", {
      headers: authHeader(),
      params: pars,
    });
  }
}

export default new CurrencyService();
