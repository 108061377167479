<template>
  <div>
    <BonusNewMemberFilters :userNameFilter.sync="userNameFilter" />
    <b-card no-body>
      <!-- <div class="m-2">
				<b-row>
					<b-col
						cols="12"
						v-if="$can('create', 'bonuscode')"
					>
						<div class="d-flex align-items-center justify-content-end">
							<b-button
								variant="primary"
								@click="showAddBonusModal"
							>
								<span class="text-nowrap">
									<feather-icon icon="PlusIcon"/> New
								</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div> -->
      <div class="p-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            v-if="$can('export', 'bonusnewmember')"
            cols="12"
            class="d-flex justify-content-end"
          >
            <!-- md="3" -->
            <b-button
              v-if="!showLoadingExport"
              variant="primary"
              @click="exportData"
            >
              <feather-icon icon="DownloadIcon" size="16" /> {{ $t("Export") }}
            </b-button>
            <b-button v-else variant="primary" disabled class="mr-1">
              <b-spinner small />
              <feather-icon icon="DownloadIcon" size="16" />
              {{ $t("Exporting") }}
            </b-button>
            <div class="hidden">
              <vue-export-excel
                ref="exportButton"
                :data="data"
                :columns="columns"
                :file-name="exportFiletitle"
                :file-type="'xlsx'"
                :sheet-name="exportFileSheetName"
              >
                <feather-icon icon="DownloadIcon" size="16" />
                {{ $t("Export") }}
              </vue-export-excel>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refBonusListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        hover
        head-variant="light"
        :no-border-collapse="true"
        :items="bonusCodeLists"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <b-link class="cursor-pointer">
            {{ data.item.name }}
          </b-link>
        </template>
        <template #cell(username)="data">
          {{ data.item.username }}
        </template>

        <template #cell(user_id)="data">
          {{ data.item.user_id }}
        </template>

        <template #cell(id)="data">
          {{ data.item.id }}
        </template>
        <template #cell(email)="data">
          {{ data.item.email }}
        </template>
        <template #cell(phone)="data">
          {{ data.item.phone }}
        </template>
        <template #cell(date)="data">
          {{ data.item.date }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(updated_by)="data">
          {{ data.item.updated_by }}
        </template>
        <template #cell(updated_at)="data">
          {{
            formatDateTime(
              data.item.updated_at ? data.item.updated_at : ""
            ).replace(".000000Z", "")
          }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            v-if="data.item.status == 1 && $can('update', 'bonusnewmember')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            @click="showEditBonusModal(data.item)"
            class="btn-icon btn-sm mr-50"
          >
            Edit
          </b-button>
          <!-- <b-button
            v-else
            disabled
            variant="outline-secondary"
            class="btn-icon btn-sm mr-50"
          >
            <feather-icon icon="CheckIcon" />
          </b-button> -->
          <div
            v-if="data.item.status !== 1 && $can('update', 'bonusnewmember')"
          >
            View
          </div>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBonus"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Add Bonus Modal -->
    <add-bonus-modal
      :bonus-code-options="bonusCodeOptions"
      :group-name-options="groupNameOptions"
      :currency-options="currencyOptions"
      :product-options="productOptions"
      :type-options="typeOptions"
      :bonus-type-options="bonusTypeOptions"
      @refetch-data="refetchData"
    />
    <!-- Edit Bonus Modal -->
    <edit-bonus-new-member-modal
      :bonus-new-member-detail.sync="bonusNewMemberDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, computed } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import bonusStoreModule from "@/views/bonus/bonusStoreModule";
import currencyStoreModule from "@/views/local-setting/local/currencyStoreModule";
import BonusNewMemberFilters from "./BonusNewMemberFilters.vue";
import VueExportExcel from "@/views/extensions/export/VueExportExcel.vue";

export default {
  components: {
    BonusNewMemberFilters,
    AddBonusModal: () => import("./AddBonusModal.vue"),
    EditBonusNewMemberModal: () => import("./EditBonusNewMemberModal.vue"),
    BButton,
    VueExportExcel,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      roleId: null,
      content: null,
      bonusNewMemberDetail: null,
      showLoadingExport: false,
      columns: [
        { label: "st", field: "id" },
        // { label: "Transaction ID", field: "ref" },
        { label: "Username", field: "username" },
        { label: "UserId", field: "user_id" },
        { label: "Id", field: "id" },
        { label: "Email", field: "email" },
        { label: "Phone", field: "phone" },
        { label: "Date", field: "date" },
        {
          label: "Status",
          field: "status",
          dataFormat: (value) => this.resolveStatus(value).label,
        },
        { label: "Updated By", field: "updated_by" },
        {
          label: "Created At",
          field: "created_at",
          // dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
        {
          label: "Updated At",
          field: "updated_at",
          // dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
      ],
      data: [],
      exportFiletitle: `BonusNewMember-${new Date()
        .toJSON()
        .replace(":", "-")
        .substring(0, 16)}`,
      exportFileSheetName: "BonusNewMember",
    };
  },
  mounted() {
    this.bonusGroupList();
    this.getCurrency();
    this.getBonusCodeProduct();
    this.bonusTypeList();
  },
  methods: {
    showAddBonusModal() {
      this.$bvModal.show("modal-add-bonus");
    },
    showEditBonusModal(datas) {
      this.bonusNewMemberDetail = datas;
      this.$bvModal.show("modal-edit-bonus-new-member");
    },
    bonusGroupList() {
      store
        .dispatch("bonus/fetchBonusGroups", {
          page: 1,
        })
        .then((response) => {
          const { bonusGroups } = response;
          this.groupNameOptions = bonusGroups;
        });
    },
    bonusTypeList() {
      store.dispatch("bonus/fetchBonusTypes").then((response) => {
        this.bonusTypeOptions = response.data.data;
      });
    },
    getCurrency() {
      store
        .dispatch("local-setting-currency/fetchCurrencies")
        .then((response) => {
          if (response.data.code == "00") {
            this.currencyOptions = response.data.data;
          }
        });
    },
    getBonusCodeProduct() {
      store.dispatch("bonus/fetchBonusCodeProduct").then((response) => {
        if (response.data.code == "00") {
          this.productOptions = response.data.data;
        }
      });
    },
    async getExportData() {
      await store
        .dispatch("bonus/exportBonusNewMember", {
          // page: pageNumber,
          type: this.typeFilter,
          username: this.usernameFilter,
          reference: this.referenceFilter,
          fromdate: this.fromdateFilter,
          todate: this.todateFilter,
          bankId: this.bankFilter,
          stat: this.statusFilter,
        })
        .then((response) => {
          // console.log(123, response);
          if (response.data.code == "00") {
            this.data = response.data.data;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    async exportData() {
      this.showLoadingExport = true;
      await this.getExportData();
      await this.delay(2000);
      // }
      this.showLoadingExport = false;
      this.$refs.exportButton.$el.click();
    },
    delay(ms) {
      return new Promise((resolve, reject) => setTimeout(resolve, ms));
    },
  },
  setup() {
    if (!store.hasModule("local-setting-currency"))
      store.registerModule("local-setting-currency", currencyStoreModule);
    if (!store.hasModule("bonus"))
      store.registerModule("bonus", bonusStoreModule);
    const toast = useToast();
    const bonusLists = ref([]);
    const perPage = ref(25);
    const totalBonus = ref(0);
    const currentPage = ref(1);
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const codeFilter = ref(null);
    const groupIdFilter = ref(null);
    const currencyFilter = ref(null);
    const userNameFilter = ref(null);

    const productIdFilter = ref(null);
    const discountTypeFilter = ref(null);
    const statusFilter = ref(null);
    const fromdateFilter = ref(null);
    const todateFilter = ref(null);
    const refBonusListTable = ref(null);
    const groupNameOptions = ref([]);
    const currencyOptions = ref([]);
    const productOptions = ref([]);
    const bonusCodeOptions = ref(null);
    const bonusTypeOptions = ref([]);
    const tableColumns = [
      { key: "index", label: "#" },
      { key: "name" },
      "username",
      "user_id",
      "id",
      "email",
      "phone",
      "status",
      "date",
      // 'currency',
      "updated_by",
      "updated_at",
      // 'bonusStatus',
      // 'memberApply',
      // { key: 'start_effective', label: 'Start Effective Date' },
      // { key: 'end_effective', label: 'End Effective Date' },
      { key: "action" }, // , stickyColumn: true, label: 'Action', variant: 'danger' },
    ];

    const refetchData = () => {
      refBonusListTable.value.refresh();
    };

    watch(
      [
        currentPage,
        codeFilter,
        groupIdFilter,
        currencyFilter,
        productIdFilter,
        discountTypeFilter,
        statusFilter,
        fromdateFilter,
        todateFilter,
        userNameFilter,
      ],
      () => {
        refetchData();
      }
    );

    const bonusCodeLists = (ctx, callback) => {
      store
        .dispatch("bonus/fetchBonusNewMember", {
          username: userNameFilter.value,
          // group_id: groupIdFilter.value,
          // currency: currencyFilter.value,
          // product_id: productIdFilter.value,
          // discount_type: discountTypeFilter.value,
          // status: statusFilter.value,
          // start_effective: fromdateFilter.value,
          // end_effective: todateFilter.value,
          page: currentPage.value,
        })
        .then((response) => {
          if (response.data.code == "00") {
            bonusCodeOptions.value = response.data.data;
            callback(response.data.data);
            totalBonus.value = response.data.count;
            perPage.value =
              currentPage.value == 1 ? response.data.data.length : 25;
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refBonusListTable.value
        ? refBonusListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalBonus.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "inActive", value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Pending", variant: "primary" };
      if (status == 2) return { label: "Approved", variant: "success" };
      if (status == 3) return { label: "Reject", variant: "danger" };
    };

    const resolveType = (type) => {
      if (type == 1) return "Cash";
      if (type == 2) return "Percent";
      return "-";
    };

    const resolveBonusStatus = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const now = new Date();
      if (startDate <= now && now <= endDate)
        return { label: "Live", variant: "primary" };
      if (now > endDate) return { label: "Expired", variant: "danger" };
      if (now < startDate) return { label: "Coming", variant: "secondary" };
      return { label: "-", variant: "secondary" };
    };

    const typeOptions = [
      { label: "Cash", value: 1 },
      { label: "Percent", value: 2 },
    ];
    return {
      tableColumns,
      perPage,
      currentPage,
      totalBonus,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refBonusListTable,

      bonusLists,
      bonusCodeLists,
      refetchData,

      codeFilter,
      groupIdFilter,
      groupNameOptions,
      currencyFilter,
      currencyOptions,
      productIdFilter,
      productOptions,
      discountTypeFilter,
      fromdateFilter,
      todateFilter,
      statusFilter,
      userNameFilter,

      resolveStatus,
      resolveBonusStatus,
      statusOptions,
      resolveType,
      typeOptions,
      bonusCodeOptions,
      bonusTypeOptions,

      formatDateTime,
      numberFormat,
    };
  },
};
</script>
<style>
.b-table-sticky-column {
  right: 0 !important;
}
td.b-table-sticky-column {
  background-color: inherit;
}
td:last-child {
  text-align: center;
}
</style>
